import React from "react";

import { PageProps } from "gatsby";

import Layout from "../components/layout";
import SEO from "../components/seo";

import Typography from '@material-ui/core/Typography';

import ExhibitionImage from "../../static/images/bev-exhibition.jpg";

const currentExhibitions = [
    'TBD | Precious Metals 2 | Geisel Gallery | Rochester, NY (solo exhibition) *postponed due to Covid*'
];

const previousExhibitions = [
    '2022 | Members Exhibition | Rochester Contemporary Art Gallery | Rochester, NY (group exhibition)',
    '2022 | Small Works | Lumiere Photo and Gallery | Rochester, NY (group exhibition)',
    '2022 | Small Works Exhibition | Main Street Arts | Clifton Springs, NY (juried group exhibition)',
    '2022 | International Moon Festival Art Show | The Artist Cave | Rochester, NY (group exhibition)',
    '2022 | 7th Annual Women Artists Show by the Bay | The Artist Cave | Rochester, NY (group exhibition)',
    '2022 | Black and White | Village Gallery | Caledonia, NY (group exhibition)',
    '2022 | Made In NY | Schweinfurth Art Center | Auburn, NY (juried group exhibition)',
    '2022 | Members Exhibition | Rochester Contemporary | Rochester, NY (group exhibition)',
    '2021 | Our Abundant Earth | Art Center of Rochester | Rochester, NY (group exhibition)',
    '2021 | Small Works Exhibition | Main St. Arts | Clifton Springs, NY (group exhibition)',
    '2021 | Finger Lakes Exhibition | Memorial Art Gallery | Rochester, NY (group exhibition)',
    '2019 | How The West Was One | Whitman Works Gallery | Penfield, NY (solo exhibition)',
    '2018 | Cat Clay Gallery | Rochester, NY (solo exhibition)',
    '2018 | Reflections in Light | Whitman Works Gallery | Penfield, NY (solo exhibition)',
    '2018 | Light/Dark | Whitman Works Gallery | Penfield, NY (group exhibition)',
    '2017 | Crossroads | Norchar & Haus | Rochester, NY (group exhibition)',
    '2017 | Bauch & Lomb | Rochester, NY (solo exhibition)',
    '2017 | Cat Clay Gallery | Rochester, NY (solo exhibition)',
    '2015 | Artist’s Row at the Public Market | Rochester, NY',
    '2013 | Precious Metals | Acanthus Cafe | Rochester, NY (solo exhibition)',
    '2012 | Bread and Water Theater Art and Music Festival | Rochester, NY',
    '2009 | 6x6 Benefit Show | Rochester Contemporary | Rochester, NY',
    '2009 | Annual Members Show | Rochester Contemporary | Rochester, NY',
    '2009 | ArtAwake | Rochester, NY (group exhibition)',
    '2008 | 6x6 Benefit Show | Rochester Contemporary | Rochester, NY',
    '2008 | Master Thesis Show | SUNY Empire State College | Rochester, NY (solo exhibition)',
    '2004 | Student Invitational Show | Bevier Gallery, RIT | Rochester, NY',
    '2004 | Inspired by Light | Gallery r | Rochester, NY',
    '2004 | Senior Fine Arts Studio Exhibition | Gallery r | Rochester, NY',
    '2002 | Student Honors Art Show | FLCC Insalco/Williams Gallery | Canandaigua, NY (First Place Award/Printmaking)',
    '1999 | Waterfront Arts Festival | Canandaigua, NY',
    '1999 | Clifton Springs Library Conference Room | Clifton Springs, NY (solo exhibition)',
    '1998 | Sulphur Springs Festival Art Show | Clifton Springs, NY (Best of Show)'
];

const ExhibitionsPage = (props: PageProps) => (
    <Layout>
        <SEO title="Exhibitions" />

        <div className="content_container">
            <img src={ExhibitionImage} />

            
            <Typography className="page_header" component="h1" variant="h4" paragraph>
                Current Exhibitions
            </Typography>

            <div style={{marginLeft: 15}}>
                {currentExhibitions.map(element => {
                    return (
                        <Typography component="p">
                            {element}
                        </Typography>
                    )
                })}
            </div>

            <Typography className="page_header" component="h1" variant="h4" paragraph style={{marginTop: 20}}>
                Previous Exhibitions
            </Typography>

            <div style={{marginLeft: 15}}>
                {previousExhibitions.map(element => {
                    return (
                        <Typography component="p">
                            {element}
                        </Typography>
                    )
                })}
            </div>
        </div>
    </Layout>
  )
  
  export default ExhibitionsPage;